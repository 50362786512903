import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { filesService } from "../services";

const RenameFileModal: React.FC<{
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentFileName: string;
  refreshFileList: () => void;
}> = ({ showModal, setShowModal, currentFileName, refreshFileList }) => {
  const [newFileName, setNewFileName] = useState<string>("");
  const [canRename, setCanRename] = useState<boolean>(false);
  const renameFile = async () => {
    setCanRename(false);
    const fileExt = currentFileName.split(".").pop();
    await filesService.renameFile(currentFileName, `${newFileName}.${fileExt}`);
    refreshFileList();
    setShowModal(false);
  };
  const updateNewName = (newName: string) => {
    setCanRename(newName.length > 0);
    setNewFileName(newName);
  };
  useEffect(() => {
    if (showModal) {
      const fileNameNoExt = currentFileName.split(".");
      setNewFileName(fileNameNoExt.slice(0, -1).join("."));
    }
  }, [showModal, currentFileName]);

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>Rename File</Modal.Header>
      <Modal.Body>
        <Stack gap={3}>
          <Form.Control
            onChange={(e) => updateNewName(e.target.value)}
            value={newFileName}
          />
          <Button disabled={!canRename} onClick={() => renameFile()}>
            Rename
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default RenameFileModal;
