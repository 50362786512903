import { UserSession } from "../types";
import { Dropdown } from "react-bootstrap";

const MediaFilter: React.FC<{
  userSession: UserSession;
  updateUserSession: (session: UserSession) => void;
}> = ({ userSession, updateUserSession }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle>{userSession.typeFilter}</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() =>
            updateUserSession({
              ...userSession,
              typeFilter: "all",
            })
          }
        >
          all
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            updateUserSession({
              ...userSession,
              typeFilter: "video",
            })
          }
        >
          video
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            updateUserSession({
              ...userSession,
              typeFilter: "image",
            })
          }
        >
          image
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            updateUserSession({
              ...userSession,
              typeFilter: "text",
            })
          }
        >
          text
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default MediaFilter;
