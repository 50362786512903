import { getAccessToken } from "./authService";
import { FileIndex, UserSession } from "../types";
import { stringify } from "querystring";
export type HelloResp = {
  message: string;
};
export const sayHello = async () => {
  const result = await fetch("/api/files/hello?name=adam", {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return (await result.json()) as HelloResp;
};

export const listFiles = async () => {
  const result = await fetch("/api/files", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  const data = await result.json();
  return data.files as FileIndex[];
};

export const getFileUrl = async (fileName: string) => {
  const result = await fetch(`/api/files/${fileName}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return await result.text();
};
export const getFile = async (fileName: string) => {
  const signedUrl = await getFileUrl(fileName);
  const fileRequest = await fetch(signedUrl, {
    mode: "cors",
  });
  return await fileRequest.text();
};

export const getUploadUrl = async (file: File) => {
  const result = await fetch("/api/files", {
    method: "POST",
    body: JSON.stringify({ file_name: file.name }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return await result.text();
};

export const uploadFile = async (file: File, uploadUrl: string) => {
  const uploadResult = await fetch(
    new Request(uploadUrl, {
      method: "PUT",
      body: file,
      headers: new Headers({ "Content-Type": "multipart/*" }),
    })
  );
  if (uploadResult.ok) {
    return "Success";
  } else {
    return await uploadResult.text();
  }
};

export const renameFile = async (oldName: string, newName: string) => {
  const result = await fetch(`/api/files/${oldName}`, {
    method: "PUT",
    body: JSON.stringify({ new_file_name: newName }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return await result.text();
};

export const deleteFile = async (name: string) => {
  const result = await fetch(`/api/files/${name}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return await result.text();
};

export const getUserSession = async () => {
  const result = await fetch(`/api/files/session`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  if (result.ok) {
    const data = await result.json();
    return {
      autoplay: data.autoplay,
      playedIndices: data.playedIndices,
      searchText: data.searchText,
      selectedFileIndex: data.selectedFileIndex,
      shuffle: data.shuffle,
      typeFilter: data.typeFilter,
      playedSeconds: Number(data.playedSeconds),
    } as UserSession;
  }
  return {
    autoplay: false,
    playedIndices: [],
    searchText: "",
    selectedFileIndex: -1,
    shuffle: false,
    typeFilter: "all",
    playedSeconds: 0,
  } as UserSession;
};

export const saveUserSession = async (data: UserSession) => {
  const result = await fetch(`/api/files/session`, {
    method: "POST",
    body: JSON.stringify({
      selectedFileIndex: data.selectedFileIndex,
      searchText: data.searchText,
      typeFilter: data.typeFilter,
      autoplay: data.autoplay,
      shuffle: data.shuffle,
      playedIndices: data.playedIndices,
      playedSeconds: data.playedSeconds.toString(),
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return (await result.json()) as UserSession;
};

// eslint-disable-next-line
export default {
  sayHello,
  listFiles,
  getFileUrl,
  getFile,
  getUploadUrl,
  uploadFile,
  renameFile,
  deleteFile,
  saveUserSession,
  getUserSession,
};
